import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '@/store';
import UnAuthorizedError from '@/infrastructure/errors/UnAuthorizedError';
import BadRequestError from '@/infrastructure/errors/BadRequestError';
import { HttpStatus } from '@/infrastructure/http/HttpStatus';

export const config: AxiosRequestConfig = {
    baseURL: '/api'
};

const fetcher = axios.create(config);

type CustomErrorData = {
    message?: string;
    data?: any;
};

let csrfToken: string | null = null;
const acceptedMethods = ['post', 'put', 'patch', 'delete'];

async function getCsrfToken() {
    try {
        const response = await fetcher.get('/auth/csrf-token');
        csrfToken = response.data.csrfToken;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
    return csrfToken;
}

fetcher.interceptors.request.use(async (config) => {
    if (acceptedMethods.includes(config.method)) {
        config.headers['X-CSRF-Token'] = await getCsrfToken();
    }
    return config;
});

const isUnauthorizedError = (error: AxiosError<CustomErrorData>): boolean => error.response?.status === HttpStatus.UNAUTHORIZED;
const isBadRequestError = (error: AxiosError<CustomErrorData>): boolean => error.response?.status === HttpStatus.BAD_REQUEST;
const isForbiddenError = (error: AxiosError<CustomErrorData>): boolean => error.response?.status === HttpStatus.FORBIDDEN;

const isAnErrorRelatedToLoginForm = (error: AxiosError<CustomErrorData>): boolean => {
    const errorMessagesRelatedToLoginForm: string[] = ['invalid_credentials', 'user_banned'];
    return errorMessagesRelatedToLoginForm.includes(error.response.data.message);
};

const isASessionDetailsError = (error: AxiosError<CustomErrorData>): boolean => {
    return error.response.request?.responseURL?.includes('/api/session-details');
};

export const onResponse = (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
    return response;
};

export const onResponseError = async (error: AxiosError<CustomErrorData>): Promise<AxiosError<CustomErrorData>> => {
    if (isForbiddenError(error)) {
        return Promise.reject(new UnAuthorizedError(error.response.data.message));
    }
    if (isUnauthorizedError(error)) {
        if (!isAnErrorRelatedToLoginForm(error) && !isASessionDetailsError(error)) {
            await store().dispatch('auth/signOut', { showError: true });
        }

        return Promise.reject(new UnAuthorizedError(error.response.data.message));
    }

    if (isBadRequestError(error)) {
        return Promise.reject(new BadRequestError(error.response.data.message));
    }

    return Promise.reject(error);
};

fetcher.interceptors.response.use(onResponse, onResponseError);

export default fetcher;
