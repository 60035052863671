export const getRootDomain = (): string => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length <= 2 || hostname === 'localhost') {
        return hostname;
    }

    return parts.slice(-2).join('.');
};
