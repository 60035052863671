export enum LOCALE {
    en_GB = 'en_GB',
    de_DE = 'de_DE',
    de_CH = 'de_CH',
    fr_CH = 'fr_CH',
    es_ES = 'es_ES',
    pt_PT = 'pt_PT',
    fr_FR = 'fr_FR',
    it_IT = 'it_IT'
}

export const DOCMORRIS_LOCALES = [LOCALE.de_DE, LOCALE.en_GB];

export const ZURROSE_LOCALES = [LOCALE.fr_CH, LOCALE.de_CH];

export const PROMOFARMA_LOCALES = [LOCALE.en_GB, LOCALE.es_ES, LOCALE.pt_PT, LOCALE.fr_FR, LOCALE.it_IT, LOCALE.de_DE];
