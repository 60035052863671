import { LOCALE } from '@/infrastructure/locales';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';
import { RootDomain } from '@/infrastructure/config/RootDomain';

const getDefaultLocaleByRootDomain = (rootDomain: string): LOCALE => {
    const localeByRootDomain: Partial<Record<RootDomain, LOCALE>> = {
        [RootDomain.DOCMORRIS_FR]: LOCALE.fr_FR,
        [RootDomain.DOCMORRIS_PT]: LOCALE.pt_PT,
        [RootDomain.PROMOFARMA_COM]: LOCALE.es_ES
    };

    return localeByRootDomain[rootDomain] || LOCALE.en_GB;
};

export const getDefaultLocale = (businessUnit: BusinessUnit, rootDomain: string): LOCALE => {
    const defaultLocalesByBU = {
        [BusinessUnit.DOCMORRIS]: LOCALE.de_DE,
        [BusinessUnit.ZURROSE]: LOCALE.de_CH,
        [BusinessUnit.PROMOFARMA]: getDefaultLocaleByRootDomain(rootDomain)
    };

    return defaultLocalesByBU[businessUnit];
};
