import { RouteConfig } from 'vue-router';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';
import { SellerService } from '@/services/sellers/types';

export const getMyAccountRoutes = (): RouteConfig[] => [
    {
        path: '',
        name: 'my-account',
        props: true,
        component: () => import(/* webpackChunkName: "Dashboard" */ '@/pages/Dashboard.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/perfil/farmacia',
                name: 'my-account/pharmacy',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/perfil/empresa',
                name: 'my-account/company',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/perfil/servicios',
                name: 'my-account/services',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/my-account/payment-provider',
                alias: ['/phw/perfil/payment-provider', '/phw/my-account/payment-provider'],
                name: 'my-account/payment-provider',
                meta: {
                    requiresAuth: true,
                    requiresOneOfServices: [SellerService.HYPERWALLET, SellerService.ADYEN]
                },
                component: () => import(/* webpackChunkName: "PaymentProvider" */ '@/pages/myAccount/PaymentProvider.vue')
            },
            {
                path: '/perfil/acceso',
                name: 'my-account/changePassword',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/perfil/vacaciones',
                name: 'my-account/holidays',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/my-account/shipping-costs',
                alias: '/phw/my-account/shipping-costs',
                name: 'my-account/shipping-costs',
                meta: {
                    requiresAuth: true,
                    requiresBusinessUnit: [BusinessUnit.ZURROSE]
                },
                component: () => import(/* webpackChunkName: "AssociatedShippingCosts" */ '@/pages/myAccount/AssociatedShippingCosts.vue')
            },
            {
                path: '/my-account/profile',
                alias: '/phw/my-account/profile',
                name: 'my-account/profile',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "Profile" */ '@/pages/myAccount/Profile.vue')
            },
            {
                path: '/my-account/fiscal-information',
                alias: '/phw/my-account/fiscal-information',
                name: 'my-account/fiscal-information',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "FiscalInformation" */ '@/pages/myAccount/FiscalInformation.vue')
            },
            {
                path: '/my-account/invoices',
                alias: '/phw/my-account/invoices',
                name: 'my-account/invoices',
                meta: {
                    requiresAuth: true,
                    requiresBusinessUnit: [BusinessUnit.DOCMORRIS, BusinessUnit.PROMOFARMA]
                },
                component: () => import(/* webpackChunkName: "Invoices" */ '@/pages/myAccount/Invoices/Invoices.vue')
            }
        ]
    }
];
